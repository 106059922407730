import { WithTranslation } from 'react-i18next';

import { Weekday } from 'models/product';

export const getShortWeekdayText = (weekday: Weekday, t: WithTranslation['t']): string => {
  switch (weekday) {
    case 'SUN':
      return t('Su');
    case 'MON':
      return t('M');
    case 'TUE':
      return t('Tu');
    case 'WED':
      return t('W');
    case 'THU':
      return t('Th');
    case 'FRI':
      return t('F');
    case 'SAT':
      return t('Sa');
    default:
      return '';
  }
};
